<template>
    <searchBody>
        <search class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset"></search>
        <Card class="table_container" ref="table_container">
            <Table :key="tableKey" stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="serviceType">
                    {{ getServiceType(row.serviceType) }}
                </template>
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ row.status == 1 ? '已完成' : '进行中' }}</span>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqBannerList } from '@/lib/request/auth';

export default {
    name: 'serviceProviderView',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            searchs: [{ id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'title' }],
            tableKey: 'tableKey',
            dataColumn: [
                { key: 'csName', title: '客户名称' },
                { key: 'serviceProviderName', title: '服务商名称' },
                { slot: 'serviceType', title: '服务类型' },
                { key: 'phone', title: '联系电话' },
                { key: 'address', title: '服务地址' },
                { slot: 'status', title: '状态' },
                { key: 'serviceTime', title: '服务时间' },
                { key: 'servicePeople', title: '服务人员' },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getServiceType(serviceType) {
            let objType = {
                1: '服务类型1',
                2: '服务类型2',
                3: '服务类型3',
                4: '服务类型4',
            };
            let typeStr = objType[serviceType];
            return typeStr || serviceType;
        },
        //获取列表数据
        getList() {
            // this.showAjaxLoading();
            // reqBannerList(this.params)
            //     .then(res => {
            //         this.dataInjectDataList(res);
            //     })
            //     .finally(() => {
            //         this.hideAjaxLoading();
            //     });

            this.dataList = Array.from({ length: 20 }, (v, k) => ({
                csName: `第${k + 1}个客户`,
                serviceProviderName: '琪朗旗舰店',
                serviceType: Math.ceil(Math.random() * 4),
                phone: '15912345678',
                address: '深圳市南山区xxxx大厦',
                status: ~~(Math.random() * 2) + '',
                serviceTime: '2024-01-12 10:45:00',
                servicePeople: `第${k + 1}个服务人员`,
            }));
            this.tableKey = hm.createId();
        },
        //新建/编辑
        onEdit(_row) {
            // this.goUrl('/bannerAdd', {
            //     id: (_row || {}).id || null,
            // });
        },
    },
};
</script>
<style lang="less" scoped>
.table_container {
    & .ivu-switch span {
        font-size: revert;
    }
}
</style>
